<template>
  <div id="clients">
    <div class="container-fluid">
      <div class="table-sections">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('transaction-list') }}</h4>
          </template>
          <template v-slot:body>
            <b-table responsive :items="transactionHistory.data" :fields="fields" class="mb-0 table-borderless"
                     v-if="canAccess(['get-client' , 'get-client' , 'add-to-wallet-client' , 'update-client' , 'delete-client'])">
              <template v-slot:cell(type)="data">
                <h5>
                  <b-badge v-if="data.item.type === 'Draw'" variant="primary">{{ data.item.type }}</b-badge>
                  <b-badge v-else variant="success">{{ data.item.type }}</b-badge>
                </h5>
              </template>
            </b-table>
          </template>
        </iq-card>
      </div>
      <!-- Pagination Section -->
      <div class="pagination">
        <iq-card class="w-100 d-flex justify-content-center">
          <template v-slot:body>
            <b-pagination
              @change="getTransactionHistory({
                        ...filterData,
                        id: $route.params.id
                      })"
              v-model="filterData.page"
              :total-rows="transactionHistory.total"
              :per-page="transactionHistory.per_page"
            ></b-pagination>
          </template>
        </iq-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'governorates',
  data () {
    return {
      filterData: {
        page: 1
      }
    }
  },
  computed: {
    insertMonyType () {
      return [
        {
          text: this.$t('deposit'),
          value: 0
        },
        {
          text: this.$t('draw'),
          value: 1
        }
      ]
    },
    fields () {
      return [
        {
          label: this.$t('amount'),
          key: 'amount'
        },
        {
          label: this.$t('transaction-type'),
          key: 'type'
        },
        {
          label: this.$t('created-date'),
          key: 'created_at'
        },
        {
          label: this.$t('updated-date'),
          key: 'updated_at'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getGovernorates(this.filterData)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getTransactionHistory({
          ...this.filterData,
          id: this.$route.params.id
        })
      },
      deep: true
    }
  },
  mounted () {
    this.getTransactionHistory({
      ...this.filterData,
      id: this.$route.params.id
    })
  }
}
</script>
